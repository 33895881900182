import poster from '../../assets/pdf/on_connect_fluids_Fr_pages.pdf';

export const banner = {
  type: 'fluids',
  title: "ON'connect™ fluids",
  subtitle: 'AMELIOREZ LA PERFORMANCE ENVIRONNEMENTALE DE VOS BATIMENTS',
  text: 'Vous cherchez à mener la transition environnementale de votre organisation&nbsp;? ON’connect™ fluids centralise toutes les données utiles à l’analyse de vos consommations en eau, électricité et gaz sur l’ensemble de vos sites. Vous pouvez ainsi construire un plan d’action efficace et mesurer ses résultats pour remplir les objectifs du Décret Tertiaire.<br />Vous êtes déjà équipé d\'un outil de gestion des fluides et vous souhaitez ajouter votre suivi Eau&nbsp;? Découvrez notre solution <u><a href="/espace-produit/water" target="_blank" rel="noopener">SUEZ Water Data Transfer</a></u>.',
  video: 'https://youtube.com/embed/z_Y_kmAGwmU',
  poster
};

export const cards = [
  {
    title: 'Vos Enjeux',
    list: [
      '• Analyser les consommations eau/énergie sur l’ensemble de votre parc immobilier',
      '• Maîtriser le budget de vos consommations en eau et énergie ',
      "• Mettre en place une stratégie environnementale efficace afin de diminuer l'impact environnemental des bâtiments",
      '• Rendre compte de vos engagements environnementaux afin d’être en conformité avec les réglementations (Décret Tertiaire...)'
    ]
  },
  {
    title: 'Nos réponses',
    list: [
      '• Mise à disposition d’une plateforme de suivi des consommations en eau, électricité et énergie pour l’ensemble de vos sites sans contrainte géographique et indépendamment du fournisseur',
      '• Intégration de données supplémentaires permettant une analyse plus fine des consommations : superficie, taux d’occupation, température, qualité d’air, etc.',
      '• Déploiement de réseaux communicants',
      '• Accès aux différentes factures ',
      '• Contrôle des contrats de fourniture et module d’optimisation des contrats d’énergie',
      '• Accompagnement personnalisé dans votre transition environnementale par un Energy Manager dédié (partenariat avec Behi)'
    ]
  },
  {
    title: 'Nos Références',
    list: [
      '• Vinci Autoroutes -ESCOTA pour la supervision de nombreux sites compris sur plus de 450 km de voies routières',
      '• RATP pour superviser environ 1000 capteurs répartis sur plus de 500 sites en Île-de-France'
    ]
  }
];

export default {
  cards,
  banner
};
